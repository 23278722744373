import MenuContainer_ResponsiveComponent from '@wix/thunderbolt-elements/src/components/MenuContainer/viewer/skinComps/Responsive/Responsive.skin';


const MenuContainer_Responsive = {
  component: MenuContainer_ResponsiveComponent
};


export const components = {
  ['MenuContainer_Responsive']: MenuContainer_Responsive
};

